<template>
  <div id="home">
        <navbar homepage="false"></navbar>
            <div class="newsletter-container">
                <div class="newsletter-left-column">
                    <h3>Archive</h3>
                    <ul>
                        <li>
                            2018
                            <ul>
                                <li><a class="archive-date" href="01-jan-2018">January</a></li>
                                <li><a class="archive-date active" href="01-feb-2018">February</a></li>
                                <li><a class="archive-date" href="01-mar-2018">March</a></li>
                                <li><a class="archive-date" href="01-apr-2018">April</a></li>
                                <li><a class="archive-date" href="01-may-2018">May</a></li>
                                <li><a class="archive-date" href="01-jun-2018">June</a></li>
                                <li><a class="archive-date" href="01-jul-2018">July</a></li>
                                <li><a class="archive-date" href="01-aug-2018">August</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="newsletter-right-column">
                    <div class="newsletter-right-column-content">
                        <h2>MUSKLE Monthly Newsletter #002</h2>
                        <p class="newsletter-subtitle">
                            “What motivates you?”
                        </p>
                        <p class="newsletter-date">
                            (Feb 2018)
                        </p>
                        <p class="newsletter-paragraph">
                            Reflecting on what we discussed last month, forming habits isn’t the easiest thing to do… especially if you already have a habit which contradicts what you’re trying to achieve. Let’s look at some methods which will overcome bad habits, and set good ones.
                        </p>
                        <p class="newsletter-paragraph">
                            Intrinsic motivation:<br>
                            Probably the hardest type of motivation to use consistently, but the most rewarding. Intrinsic meaning ‘from within, or natural worth’. These are things like “I’m doing it to get fit”, or “I want to beat my personal best 5km run time”. It’s a competition from within which generates self-achievement.
                        </p>
                        <p class="newsletter-paragraph">
                            Extrinsic motivation:<br>
                            An often tangible motivation, which most Personal Trainers will use frequently. These could be things like completing an exercise a certain number of times to get a chocolate bar as reward. Or it could be to attend the most sessions in a month and receive a free Nike voucher. Of course, it doesn’t have to be <span class="italic">positive</span> extrinsic motivation either, for example – If you arrive late to a session, you may be given 20 push-ups.
                        </p>
                        <p class="newsletter-paragraph">
                            People will react differently to the different types of motivation, so finding your driver could be trial and error to see what works best for you. 
                        </p>
                        <p class="newsletter-paragraph">
                            For this month’s practical task, I want you to write down your goal on a piece of paper. Under your goal, write <span style="text-decoration: underline">why</span> you want to achieve it. (This could be either intrinsic or extrinsic). Under that, I want you to write “Be the best version of you”. Cut the paper so it’s a nice size and blue-tac it to your vanity mirror. Every day for the next month, read it when you look in the mirror.
                        </p>
                        <p class="newsletter-paragraph">
                            If you’re still struggling to prioritise goals, another method we can use is called a ‘Commitment Device’. These are a great way to achieve results. If you’re trying to lose weight - buy an expensive pair of pants in the size you want to be. Then give them to your Personal Trainer with following conditions: “If I can’t fit in them in 8 weeks’ time, I lose them”. It’s an expensive lesson if you don’t win.
                        </p>
                        <p class="newsletter-paragraph">
                            Speak to your PT about motivational strategies, and make sure you’ve got a plan of attack!
                        </p>
                        <p class="newsletter-paragraph">
                            Next month I’ll dive into some of my favourite exercises to help common postural irregularities to get you moving better.
                        </p>
                        <p class="newsletter-preview">
                            Next month sneak peak - exercise selection
                        </p>
                    </div>
                </div>
            </div>
      </div>
</template>

<script>
import Navbar from '../components/NavBar.vue'

export default {
  name: 'app',
  components: {
    Navbar
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
#home {
  margin: 0;
  padding: 0;
}
  h2 {
      font-family: 'Roboto';
      margin-bottom: 25px;
      text-align: center;
  }
  p {
      font-family: 'Roboto', sans-serif;
  }
  ul {
      list-style-type: none;
  }
  li {
      padding: 5px 0;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
  }
  .archive-date {
      color: #000;
      text-decoration: none;
  }
  .archive-date:hover {
      text-decoration: underline;
  }
  .active {
      font-weight: 900;
  }
  .newsletter-container {
      margin-top: 125px;
      display: flex;
  }
  .newsletter-left-column {
      flex-grow: 1;
      padding: 10px;
      margin-top: 20px;
      margin-left: 20px;
      max-width: 160px;
  }
  .newsletter-right-column {
      display: flex;
      flex-grow: 5;
      justify-content: center;
      margin-right: 80px;
  }
  .newsletter-right-column-content {
      margin: 50px 100px;
      border: 3px double #ddd;
      padding: 0px 30px;
      max-width: 595px;
  }
  .newsletter-subtitle {
      font-size: 20px;
      font-weight: 300;
  }
  .newsletter-date {
      font-size: 14px;
      font-weight: 500;
  }
  .newsletter-paragraph {
      font-size: 16px;
      font-weight: 300;
      line-height: 28px;
  }
  .newsletter-preview {
      font-size: 16px;
      font-weight: 300;
      text-transform: uppercase;
      text-align: right;
      padding-top: 40px;
  }
  .italic {
      font-style: italic;
  }

  @media only screen and (max-width: 750px) {
      h2 {
          font-size: 18px;
      }
      .newsletter-container {
          flex-direction: column;
      }
      .newsletter-right-column {
          margin-right: 0;
      }
      .newsletter-right-column-content {
          margin: 10px 10px;
          padding: 0 10px;
      }
      .newsletter-subtitle {
          font-size: 16px;
      }
      .newsletter-date {
          font-size: 13px;
      }
      .newsletter-paragraph {
          font-size: 14px;
      }
      .newsletter-preview {
          font-size: 13px;
      }
  }
</style>
